<template>
  <div>
    <b-card-header class="pb-50">
      <h4>
        Users
      </h4>
      <b-form-checkbox
        v-model="showFilters"
        name="check-button"
        switch
        inline
        @change="handleShowFiltersChange"
      >
        <h5>
          Filters
        </h5>
      </b-form-checkbox>
    </b-card-header>
    <b-card-body
      v-if="showFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Role</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Region</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="regionFilter"
            :options="regionOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:regionFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Onboarding Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onboardingFilter"
            :options="onboardingOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:onboardingFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    regionFilter: {
      type: [String, null],
      default: null,
    },
    onboardingFilter: {
      type: [Boolean, null],
      default: false,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showFilters: false,
      localRoleFilter: this.roleFilter,
    }
  },
  setup(props) {
    let roleOptions = store.state.appSettings.cognito_group_internal
    if (props.clientNameProp !== '') {
      roleOptions = store.state.appSettings.cognito_group_clients
    }
    const regionOptions = store.state.appSettings.regions_loc

    const onboardingOptions = store.state.appSettings.is_onboarding

    console.log('UserListFilters Setup')
    console.log(onboardingOptions)
    console.log(props.onboardingFilter)

    return {
      roleOptions,
      regionOptions,
      onboardingOptions,
    }
  },
  methods: {
    handleShowFiltersChange(checked) {
      console.log('Checkbox changed:', checked)
      // Perform actions based on `checked` state
      if (!checked) {
        this.$emit('update:roleFilter', null)
        this.$emit('update:regionFilter', null)
        this.$emit('update:onboardingFilter', false)
        this.$emit('update:statusFilter', 'active')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
