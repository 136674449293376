var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('create', 'users'))?_c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add New User ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-form-group',{attrs:{"label":"Username (No special characters, do not use spaces)","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username"},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", $$v)},expression:"userData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name"},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"LastName","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name"},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.clientNameProp === '')?_c('b-form-group',{attrs:{"label":"Client Name","label-for":"client_name"}},[_c('validation-provider',{attrs:{"name":"client_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.clientsList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"client_name"},model:{value:(_vm.userData.client_name),callback:function ($$v) {_vm.$set(_vm.userData, "client_name", $$v)},expression:"userData.client_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.clientNameProp !== '')?_c('b-form-group',{attrs:{"label":"Client Name","label-for":"client_display_name"}},[_c('validation-provider',{attrs:{"name":"client_display_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_display_name","readonly":true},model:{value:(_vm.userData.client_display_name),callback:function ($$v) {_vm.$set(_vm.userData, "client_display_name", $$v)},expression:"userData.client_display_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","placeholder":"optional"},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"User Role","label-for":"cognito_group"}},[_c('validation-provider',{attrs:{"name":"cognito_group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"cognito_group"},model:{value:(_vm.userData.cognito_group),callback:function ($$v) {_vm.$set(_vm.userData, "cognito_group", $$v)},expression:"userData.cognito_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Region","label-for":"region_loc"}},[_c('validation-provider',{attrs:{"name":"region_loc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"region_loc","rules":"required"},model:{value:(_vm.userData.region_loc),callback:function ($$v) {_vm.$set(_vm.userData, "region_loc", $$v)},expression:"userData.region_loc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.userData.cognito_group !== 'Client_Viewer' && _vm.userData.cognito_group !== 'Client_Admin')?_c('b-form-group',{attrs:{"label":"Force Onboarding","label-for":"is_onboarding"}},[_c('validation-provider',{attrs:{"name":"is_onboarding","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.onboardingOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"is_onboarding"},model:{value:(_vm.userData.is_onboarding),callback:function ($$v) {_vm.$set(_vm.userData, "is_onboarding", $$v)},expression:"userData.is_onboarding"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Status","label-for":"is_active"}},[_c('validation-provider',{attrs:{"name":"is_active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptionsNew,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"is_active"},model:{value:(_vm.userData.is_active),callback:function ($$v) {_vm.$set(_vm.userData, "is_active", $$v)},expression:"userData.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[(_vm.$can('create', 'users'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.preventDoubleClick}},[_vm._v(" Add ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary","disabled":_vm.preventDoubleClick},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}],null,false,2721760281)}):_vm._e(),_c('b-modal',{ref:"permissionsModal",attrs:{"id":"permissions-reminder-modal","ok-title":"Continue","cancel-variant":"outline-secondary","cancel-title":"Close","centered":"","title":"Important: User Permissions Required"},on:{"ok":_vm.handleModalOk}},[_c('p',[_vm._v("The new user has been created successfully. An email has been sent to the user from no-reply@verificationemail.com with a temporary password.")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Next Steps: ")]),_c('ul',[_c('li',[_vm._v("You will be redirected to the user's edit page")]),_c('li',[_vm._v("Please update the user permissions to grant appropriate access")]),_c('li',[_vm._v("The user must log in and change their password within 48 hours")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }