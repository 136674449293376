<template>

  <div
    v-if="$can('read', 'users')"
    class="mb-2"
  >

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :client-name-prop.sync="clientName"
      :client-display-name-prop.sync="clientDisplayName"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <users-list-filters
        :role-filter.sync="roleFilter"
        :region-filter.sync="regionFilter"
        :onboarding-filter.sync="onboardingFilter"
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :client-name-prop.sync="clientName"
      />

      <div class="m-2">

        <!-- Table Top -->
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.users.fetchingUserList"
              @click="refreshUserList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh User List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('create', 'users')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="this.$store.state.users.fetchingUserList"
              @click="refreshUserList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <div v-if="$can('create', 'users')">
              <b-button
                class="ml-2"
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add User</span>
              </b-button>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="username"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        style="min-height: 10rem;"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { username: data.item.username } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { username: data.item.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Client Name -->
        <template #cell(client_display_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.client_display_name }}</span>
          </div>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          <div class="text-nowrap">
            {{ formatPhoneNumber(data.item.phone) }}
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

      </b-table>
      <div
        v-if="$store.state.users.fetchingUserList"
        class="d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import UserListAddNew from './UserListAddNew.vue'
// import { useToast } from 'vue-toastification/composition'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  props: {
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
    clientDisplayNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      clients: [],
      clientsList: [],
    }
  },
  computed: {
    isFetchingUserList() {
      console.log('isFetchingUserList')
      console.log(this.$store.getters.getFetchingUserList)
      return this.$store.getters.getFetchingUserList
    },
  },
  created() {
    this.$store.state.users.fetchingUserList = true
  },
  methods: {
    refreshUserList() {
      this.$store.state.users.fetchingUserList = true
      this.refetchData()
    },
    deleteListItem(item) {
      console.log(item)
      const that = this
      if (ability.can('delete', 'users')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete ${item.first_name} ${item.last_name}'s user account.`, {
            title: 'Delete User Account',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                username: item.username,
                clientname: item.client_name,
              }
              store
                .dispatch('app-user/deleteUser', qvars)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User Account deleted successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                  that.refreshUserList()
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting User Account',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    resetForm() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
      console.log(this.blankUserData)
      console.log(this.userData)
      this.$refs.observer.reset()
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = store.state.appSettings.cognito_group

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Disabled', value: 'disabled' },
    ]
    // const statusOptions = store.state.appSettings.is_active
    const userData = JSON.parse(localStorage.getItem('userData'))
    let clientName = ''
    if (userData.group === 'Client_Admin' || userData.group === 'Client_Viewer') {
      clientName = userData.client_name
    } else if (userData.group.includes('EyeTrax')) {
      clientName = userData.client_name
    } else if (props.clientNameProp !== '') {
      clientName = props.clientNameProp
    }
    let clientDisplayName = ''
    if (userData.group === 'Client_Admin' || userData.group === 'Client_Viewer') {
      clientDisplayName = userData.client_display_name
    } else if (userData.group.includes('EyeTrax')) {
      clientDisplayName = userData.client_display_name
    } else if (props.clientNameProp !== '') {
      clientDisplayName = props.clientDisplayNameProp
    }
    console.log('Users List Got Client Name:')
    console.log(clientName)
    console.log(clientDisplayName)

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      formatPhoneNumber,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      regionFilter,
      onboardingFilter,
      planFilter,
      statusFilter,
    } = useUsersList(clientName)

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      clientName,
      clientDisplayName,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      formatPhoneNumber,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      regionFilter,
      onboardingFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
